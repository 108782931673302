import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import KTCallout from '../../components/KTCallout';
import { Link } from 'react-router-dom';
import PageContent from '../PageContent';
import PageHeader from '../../components/PageHeader';
import S3Image from '../../components/S3Image';
import Div from '../../components/Static/Div';
// import { Integration } from 'hala-components';
const log = Logger('IntegrationsPage');

function IntegrationsPage({data}) {
    log.debug('render');

    const [filter, setFilter] = useState('');

    const onSearch = useCallback(e => {
        setFilter(e.target.value || '');
    }, []);

    const _data = useMemo(() => {
        const d = data.sort((a) => a.disable ? 1 : -1);
        if (!filter) return d;
        return d.filter(el => {
            let f = filter.toLowerCase().trim();
            if (el.name.toLowerCase().indexOf(f) !== -1) {
                return true;
            } else if (el.description.toLowerCase().indexOf(f) !== -1) {
                return true;
            }
            return false;
        });
    }, [filter, data]);

    return (
        <PageContent
            header={<PageHeader 
                onSearch={onSearch}
            />}
        >
            {/* <div id="tailwind">
                <Integration/>
            </div>
             */}

            <div className="row">
                {
                    _data.map((el,key) => {
                        let CLink = Link;
                        if (el.disable) {
                            CLink = Div;
                        }
                        return (
                            <div key={key} className="col-lg-6">
                                <CLink 
                                    to={el.disable ? null : `/integrations/${el.key}`}
                                >
                                    <KTCallout
                                        title={el.name}
                                        description={el.description}
                                        hover={!el.disable}
                                    >
                                        {
                                            el.image
                                            ? <S3Image src={el.image}/> 
                                            : null
                                        }
                                    </KTCallout>
                                </CLink>
                            </div>
                        )
                    })
                }
            </div>
        </PageContent>
    )
}

IntegrationsPage.propTypes = {
    data: PropTypes.array.isRequired
};

export default connect(
    state => ({
        data: state.availableIntegrations
    }),
    {
    }
)(IntegrationsPage);