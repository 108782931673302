import Auth from '@aws-amplify/auth';

let config;
if (process.env.NODE_ENV === 'development') {
    config = require(`./config.${process.env.NODE_ENV}.js`).default
} else {
    config = {};
}

const awsmobile = {
    Auth: {
        identityPoolId: 'eu-central-1:84890fb8-39ac-4922-b48c-46f201cde5e0',
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_hq4a3a4YB',
        userPoolWebClientId: '16ualc6aaum06d2ncbs4r50p13',
        mandatorySignIn: false

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        // // OPTIONAL - Cookie path
        //     path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },
        // OPTIONAL - customized storage object
        // storage: new MyStorage(),
    },
    Analytics: {
        AWSPinpoint: {
            appId: 'a7e889024d7f422a9fdfe5e4f5b7670e',
            region: 'eu-central-1',
            mandatorySignIn: false
        }
    },
    API: {
        endpoints: [
            {
                name: "api",
                endpoint: (() => {
                    if (process.env.NODE_ENV === 'production') {
                        return "https://api-v2.hala.ai";
                    }
                    return "http://localhost:9000";
                })(),
                "custom_header": async () => { 
                    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
                }
            }
        ]
    },
    'aws_user_files_s3_bucket': 'hala-user-files',
    'aws_user_files_s3_bucket_region': 'eu-central-1',
    chatUrl: "https://chat.hala.ai",
    ...config
};

export default awsmobile;