import React, { memo, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAnalytics } from '../../actions/analytics';
import Portlet from '../../components/Portlet';
import KTWidget1Item from '../../components/KTWidget1Item';
import { Link } from 'react-router-dom';

const Item = memo(({
    label, desctiption,
    value
}) => (
    <div className="col-xl-4">
        <Portlet bodyFit>
            <div className="kt-widget1">
                <KTWidget1Item
                    text={label}
                    desctiption={desctiption}
                >
                    <div className="kt-widget1__number kt-font-brand">
                        { value }
                    </div>
                </KTWidget1Item>
            </div>
        </Portlet>
    </div>
));

const ListItem = memo(({
    label, desctiption,
    items, morePath
}) => (
    <div className="col-xl-6">
        <Portlet
            heightFluid
            title={label}
            toolbar={desctiption}
        >
            <div className="kt-widget4">
                {
                    items.map((i, k) => (
                        <div key={k} className="kt-widget4__item">
                            {
                                i.pic
                                ? <div className="kt-widget4__pic kt-widget4__pic--pic">
                                    <div className="kt-media kt-media--sm kt-media--info">
                                        <span>{ i.pic }</span>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="kt-widget4__title">
                                { i.name || i.deletedName }
                            </div>
                            <div className="kt-widget4__tools">
                                <div className="kt-widget1__number kt-font-brand">
                                    { i.total }
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="kt-widget4__item">
                    <div className="kt-widget4__title">
                        <Link 
                            to={`/dashboard/${morePath}`}
                            className="kt-link kt-font-boldest"
                        >See more</Link>
                    </div>
                </div>
            </div>
        </Portlet>
    </div>
));

function Dashboard({
    from, to,
    fetchData, info
}) {

    const { 
        apiCalls, users, conversations,
        topSkills, topUsers
    } = info;

    useEffect(() => {
        fetchData({from, to});
    }, [fetchData, from, to]);

    const _topUsers = useMemo(() => topUsers.map(u => ({
        name: `${u.firstName} ${u.lastName}`,
        pic: `${u.firstName && u.firstName.charAt(0)}${u.lastName && u.lastName.charAt(0)}`,
        total: u.total
    })), [topUsers]);

    return (
        <React.Fragment>
            <div className="row">
                <Item
                    label="Messages" 
                    desctiption="Used / Available"
                    value={`${apiCalls} / 1000`}
                />
                <Item
                    label="Skills" 
                    desctiption="Total skills"
                    value={conversations}
                />
                <Item
                    label="Active Users" 
                    desctiption="Number of unique users"
                    value={users}
                />
            </div>
            <div className="row">
                <ListItem
                    label="Top 5 Skills"
                    desctiption="Total requests"
                    items={topSkills}
                    morePath="skills"
                />
                <ListItem
                    label="Top 5 Active Users"
                    desctiption="Total messages"
                    items={_topUsers}
                    morePath="users"
                />
            </div>
        </React.Fragment>
    )
}

export default connect(
    state => ({
        from: state.analytics.filters.from, 
        to: state.analytics.filters.to,
        info: state.analytics.info
    }),
    {
        fetchData: getAnalytics
    }
)(Dashboard);