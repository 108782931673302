import React, { memo } from 'react';
import Logger from '../../utils/logger';
import { Dropdown } from 'react-bootstrap';
import EntityMenu from './EntityMenu';
import './EntityDropdown.sass';

const log = Logger('EntityDropdown');

function EntityDropdown({
    onToggle,
    onSelect,
    value,
    show
}) {
    log.debug('render');
    return (
        <Dropdown 
            className="entity-dropdown"
            show={true}///show} 
            onToggle={onToggle}
        >
            <Dropdown.Menu className="dropdown-menu-anim">
                <EntityMenu focus={show} value={value} onSelect={onSelect}/>
            </Dropdown.Menu>			
	    </Dropdown>
    )
}

export default memo(EntityDropdown);